<template>
  <div>
    <!-- <contract-detail></contract-detail> -->
    <VMTemporaryContractTab :name="clientName" />
    <div class="mx-10 d-flex justify-space-between">
      <div>
        <template>
          <div class="d-flex justify-space-between pa-2 div-alert">
            <div class="mt-1">
              <i class="icon-alert fas fa-exclamation-triangle"></i>
              <span class="ml-2 text-alert" style="font-size: 16px"
                >この契約は仮押さえ中です</span
              >
            </div>
            <div>
              <v-btn class="btn-billing btn-crm-primary mr-4 text-12px">
                仮契約を削除
              </v-btn>
              <v-btn
                class="btn-billing btn-crm-primary text-12px"
                @click="dialog = 1"
              >
                本契約に切替
              </v-btn>
            </div>
          </div>
        </template>
      </div>
      <div>
        <v-btn class="t-btn--red-dark mr-4">
          <v-icon>mdi-close</v-icon>
          {{ $t('task_29786.close') }}
        </v-btn>
        <v-btn class="t-btn--prm"> 保存 </v-btn>
      </div>
    </div>
    <div class="ml-8 mt-4">
      <v-btn class="t-btn--prm" disabled> 仮押さえ予約を作成 </v-btn>
      <v-btn class="t-btn--prm mx-5" @click="visible = 1">
        仮押さえ予約を編集
      </v-btn>
      <v-btn @click="del" class="t-btn--prm btn-cus">
        仮押さえ予約を削除
      </v-btn>
    </div>
    <div class="mt-4 pa-5" style="margin-left: 25px">
      <h2
        class="text--title my-4"
        style="
          text-align: left;
          font: normal normal bold 20px/24px Noto Sans;
          letter-spacing: 0px;
          color: #004f8b;
          opacity: 1;
        "
      >
        利用状況
      </h2>
    </div>
    <v-card
      v-for="(item, idx) in ArrYear"
      :key="idx"
      class="pa-5 mx-table mt-4"
      shaped
      tile
    >
      <div class="d-flex justify-space-between align-center mb-3">
        <div class="mt-5">
          <div class="mb-3" style="display: inline-flex">
            <div class="ml-3">
              <div>
                <label class="custom-text">年</label>
              </div>
              <div style="text-align: center" class="custom-text">
                <label>{{ item }}</label>
              </div>
            </div>
            <div class="ml-5">
              <div style="text-align: center">
                <label class="custom-text">権利施設</label>
              </div>
              <div style="text-align: center" class="custom-text">
                <label>{{ namefcility }}</label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="span-custom-pw"></div>
      <v-data-table
        dense
        :headers="headers"
        :items="filterYear(item, listContractUsage)"
        class="elevation-1 table-custom --multi-colors mt-5 custom-table"
        :hide-default-footer="true"
      >
        <template v-slot:[`item.ReservationConfirmationPMS`]="{}">
          <v-btn
            class=".PMS-btn"
            style="
              float: right;
              background: #ffffff 0% 0% no-repeat padding-box;
              border: 0.5px solid #1873d9;
              border-radius: 4px;
              opacity: 1;
            "
          >
            <p
              style="
                display: contents;
                text-align: left;
                font: normal normal 600 12px/15px Noto Sans;
                letter-spacing: 0px;
                color: #1873d9;
                opacity: 1;
              "
            >
              PMSで予約確認
            </p>
          </v-btn>
        </template>
      </v-data-table>
    </v-card>
    <div style="height: 200px"></div>
    <!-- dialog -->
    <CreateContract
      :visible="visible === 1"
      @reset="readVmContractUsageList"
      :action="1"
      :getListContractUsage="getListContractUsage"
      @close="visible = -1"
    />
    <VMConvertIntoConfirmedContract
      :visible="dialog === 1"
      :width="750"
      @close="dialog = -1"
      className="custom-dialog"
    >
    </VMConvertIntoConfirmedContract>
  </div>
</template>

<script>
import gql from 'graphql-tag';
import VMTemporaryContractTab from './VMTemporaryContractTab.vue';
// import { apolloClient } from '@/plugins/apollo';
import CreateContract from '../../../customer/response/VMTemporaryContract/CreateContract.vue';
import VMConvertIntoConfirmedContract from './VMConvertIntoConfirmedContract.vue';
import { mapGetters, mapActions, mapMutations } from 'vuex';
import router from '@/router';
import { handlErrorView, getCopyErrorTextView } from '@/constants/functions';
import moment from 'moment';
// import { ROOMTYPELIST_TEM_CONTRACT } from '@/api/graphql/customer/response/detail/VMTemporaryContract/index.js';

export default {
  components: {
    VMTemporaryContractTab,
    VMConvertIntoConfirmedContract,
    CreateContract,
  },
  name: 'VMContractUsage',
  data() {
    return {
      a: '',
      b: '',
      name: '',
      contractID: null,
      ArrYear: [],
      headers: [
        { text: 'ステータス', value: 'status', width: '5%' },
        { text: '予約ID', value: 'bookingsID', width: '5%' },
        { text: '施設', value: 'institution', width: '5%' },
        { text: '部屋タイプ', value: 'roomtype', width: '5%' },
        { text: '宿泊開始日', value: 'checkInDate', width: '5%' },
        { text: '宿泊終了日', value: 'checkOutDate', width: '5%' },
        { text: '泊数', value: 'Numberofnights', width: '5%' },
        {
          text: '',
          value: 'ReservationConfirmationPMS',
          width: '30%',
          sortable: false,
        },
      ],
      listContractUsage: [],
      vmRoomType: [],
      Numberofnights: null,
      visible: -1,
      dialog: -1,
      namefcility: '',
    };
  },
  computed: {
    ...mapGetters([
      'roomtypelistTemContract',
      'getVmContractUsageList',
      'facilityListTemContract',
      'getListContractUsage',
    ]),
    clientName: {
      get() {
        return this.name;
      },
      set(val) {
        this.name = val;
      },
    },
  },
  // created(){
  //   this.setLoadingOverlayShow()
  //   this.redirecPageIfDataNull()
  // },
  mounted() {
    this.contractID = parseInt(router.currentRoute.params.id);
    // this.getClientBasicInfo();
    this.readVmContractUsageList();
  },
  methods: {
    handlErrorView,
    getCopyErrorTextView,
    ...mapActions(['VMComtractUsageList', 'getRoomtypelistTemContract']),
    ...mapMutations([
      'setFacilityIdTemContract',
      'setFromMonthTemContract',
      'setFromDateTemContract',
      'setToMonthTemContract',
      'setToDateTemContract',
      'setRoomtypeIdTemContract',
      'setYearsTemContract',
      'setNumYearTemContract',
      'setStayDateTemContract',
      'setStateSelectedReservationRooms',
      'setCopyErrorText',
      'setAlertError',
      'setPermissionUser',
      'setRoleAdminUser',
      'setLoadingOverlayShow',
      'setLoadingOverlayHide',
      'setListContractUsage',
    ]),

    redirecPageIfDataNull() {
      if (this.getListContractUsage.length <= 0) {
        this.setLoadingOverlayHide();
        this.$router.push({
          path: `/contract/usage-situation-vm/${parseInt(
            this.$route.params.id,
          )}/VM`,
        });
      }
    },

    filterYear(item, arr) {
      const dataTemp = arr.filter((el) => el.year === item);
      return dataTemp;
    },

    async getClientBasicInfo() {
      const query = `
            query($id: Int!){
              clientBasicInfo(
                id: $id
              ){
                id
                name
              }
            }
          `;
      const variables = {
        id: parseInt(localStorage.getItem('id')),
      };
      await this.$apollo
        .query({
          query: gql`
            ${query}
          `,
          variables: variables,
        })
        .then((data) => {
          this.clientName = data.data.clientBasicInfo.name;
        })
        .catch(async (error) => {
          this.setCopyErrorText(
            this.getCopyErrorTextView(query, variables, error.graphQLErrors),
          );
          const errorTmp = await handlErrorView(
            error.graphQLErrors,
            this.setPermissionUser,
            this.setRoleAdminUser,
          );
          if (errorTmp) {
            this.setAlertError(errorTmp, { root: true });
          }
        });
      // return true
    },
    async del() {
      const mutation = `
            mutation (
              $contractId:Int!
            ) {
              deleteVmTentativeBookings(contractId: $contractId)
            }
          `;
      const variables = {
        contractId: parseInt(this.$route.params.id),
      };
      await this.$apollo
        .mutate({
          mutation: gql`
            ${mutation}
          `,
          variables: variables,
        })
        .then((data) => {
          this.$router.push({
            path: `/contract/usage-situation-vm/${parseInt(
              this.$route.params.id,
            )}/VM`,
          });
          this.setFacilityIdTemContract(null)
      this.setFromMonthTemContract(null)
      this.setFromDateTemContract(null)
      this.setToMonthTemContract(null)
      this.setToDateTemContract(null)
      this.setRoomtypeIdTemContract(null)
      this.setYearsTemContract(null)
      this.setNumYearTemContract(null)
          this.readVmContractUsageList();
        })
        .catch(async (error) => {
          this.setCopyErrorText(
            this.getCopyErrorTextView(mutation, variables, error.graphQLErrors),
          );
          const errorTmp = await handlErrorView(
            error.graphQLErrors,
            this.setPermissionUser,
            this.setRoleAdminUser,
          );
          if (errorTmp) {
            this.setAlertError(errorTmp, { root: true });
          }
        });
    },
    readVmContractUsageList() {
      this.VMComtractUsageList(this.contractID).then(() => {
        this.listContractUsage = [];
        this.ArrYear = [];
        if (this.getVmContractUsageList) {
          this.vmRoomType = this.getVmContractUsageList.vmRoomType;
          this.namefcility = this.vmRoomType.facility.name;
          for (let i = 0; i < this.getVmContractUsageList.rights.length; i++) {
            const element = this.getVmContractUsageList.rights[i];
            if (this.ArrYear.filter((v) => v === element.year).length === 0) {
              this.ArrYear.push(element.year);
            }
            const bookings = element.bookingGroup.bookings;
            for (let j = 0; j < bookings.length; j++) {
              const booking = bookings[j];
              const startDate = new Date(booking.checkInDate).getDate();
              const endDate = moment(new Date(booking.checkOutDate))
                .subtract(1, 'd')
                .format('YYYY-MM-DD')
                .substr(8, 2);
              this.Numberofnights = endDate - startDate;
              const ojb = {
                status: booking.computed.status,
                bookingsID: booking.id,
                institution: this.vmRoomType.facility.name,
                roomtype: booking.room.roomType.name,
                checkInDate: booking.checkInDate
                  ? booking.checkInDate.replace(/-/g, '/')
                  : null,
                checkOutDate: booking.checkOutDate
                  ? moment(new Date(booking.checkOutDate))
                      .subtract(1, 'd')
                      .format('YYYY-MM-DD')
                      .replace(/-/g, '/')
                  : null,
                Numberofnights: this.Numberofnights + '泊',
                year: element.year,
              };
              this.listContractUsage.push(ojb);
              this.setListContractUsage(this.listContractUsage);
            }
          }
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.btn-cus {
  background: #c92d2d 0% 0% no-repeat padding-box !important;
  border-radius: 4px;
  opacity: 1;
}
// div
.div-alert {
  color: #ffffff;
  width: 500px;
  margin-left: -6px;
  height: 46px;
  background-color: #cb396c;
  border-radius: 9px;
}
// icon
.icon-alert {
  font-size: 20px;
}
.text-alert {
  font-size: 14px;
}
.btn-billing {
  color: #ffffff !important;
  border: 1px solid #ffffff;
  background-color: #cb396c !important;
  width: 110px;
  font-size: 12px !important;
  height: 32px !important;
}
.custom-text {
  font: normal normal normal 16px/19px Noto Sans;
  letter-spacing: 0px;
  color: #424242;
  opacity: 1;
}
.v-data-table::v-deep {
  th,
  .v-select__selection {
    font-size: 16px !important;
    color: #424242 !important;
    font-weight: 400 !important;
  }
  tbody {
    tr {
      td {
        color: #424242 !important;
        font-weight: 400 !important;
        font-size: 16px !important;
      }
    }
  }
  .v-data-footer {
    color: #000 !important;
    font-weight: 500 !important;
    font-size: 14px !important;
    .theme--light.v-input {
      .v-input__control {
        .v-select__selection--comma {
          color: #000 !important;
          font-weight: 500 !important;
          font-size: 14px !important;
        }
      }
    }
    .v-icon__svg {
      color: #333333 !important;
    }
  }
  .v-data-footer__pagination {
    display: none !important;
  }
}
</style>
